<template>
  <div class="page-wrap">
    <div class="session-form-hold">
      <base-card>
        <v-progress-linear :active="loading" :indeterminate="loading" absolute top color="primary"></v-progress-linear>
        <v-row>
          <v-spacer></v-spacer>
          <ca-lang-selector class="me-3" />
        </v-row>
        <v-card-text class="text-center">
          <v-avatar size="60" class="mb-4">
            <img src="@/assets/images/logo.svg" alt="" />
          </v-avatar>

          <h6 class="text--disabled font-weight-medium mb-10">
            {{ $t("auth.create_new_account") }}
          </h6>

          <ca-alert v-if="errorMessage" type="error"><div v-html="errorMessage"></div></ca-alert>
          <ca-alert v-if="successMessage" type="success"><div v-html="successMessage"></div></ca-alert>

          <v-form ref="form">
            <v-text-field
              v-model="email"
              :label="$t('auth.email')"
              :rules="emailRules"
              required
              :error-messages="fieldsErrors.email"
            />

            <v-text-field
              v-model="password"
              :append-icon="show ? 'mdi-eye' : 'mdi-eye-off'"
              :type="show ? 'text' : 'password'"
              name="input-10-2"
              :label="$t('auth.password')"
              :rules="nameRules"
              :error-messages="fieldsErrors.password"
              @click:append="show = !show"
            ></v-text-field>

            <captcha v-model="captcha" :is-debug="debugCaptcha" />

            <div>
              <v-checkbox
                v-model="agreeWithTerms"
                :error-messages="fieldsErrors.agreeWithTerms ? [$t('auth.required')] : []"
                :label="$t('auth.agree_with_terms_of_site')"
                @change="fieldsErrors.agreeWithTerms = !agreeWithTerms"
              />
              <v-checkbox :label="$t('auth.subscribe_to_newsletter')" class="mt-0 pt-0" />
            </div>

            <v-btn class="mb-4" block color="primary" dark :loading="loading" @click="register">
              <v-icon left>mdi-login</v-icon>
              {{ $t("auth.sign_up_now") }}
            </v-btn>

            <div class="d-flex justify-around flex-wrap">
              <v-btn text small color="primary" :to="{ name: 'login' }">
                {{ $t("auth.sign_in") }}
              </v-btn>
            </div>
          </v-form>
        </v-card-text>
      </base-card>
    </div>
  </div>
</template>

<script>
import Captcha from "@/views/components/Captcha.vue";
import CaAlert from "@/views/components/Alert";

export default {
  components: { CaAlert, Captcha },
  metaInfo() {
    return {
      title: this.$t("titles.create_new_account")
    };
  },
  data() {
    return {
      successMessage: null,
      errorMessage: null,
      show: false,
      email: "",
      password: "",
      agreeWithTerms: false,
      debugCaptcha: false,
      captcha: false,
      loading: false,
      emailRules: [
        v => !!v || this.$t("auth.email_required"),
        v => /.+@.+\..+/.test(v) || this.$t("auth.email_must_be_valid")
      ],
      nameRules: [v => !!v || this.$t("auth.password_required")],
      fieldsErrors: {
        email: [],
        password: [],
        captcha: null,
        agreeWithTerms: false
      }
    };
  },

  mounted() {
    this.loadCaptchaState()
  },

  computed: {
    captchaErrorMessage() {
      return this.$t("auth.enter_captcha");
    }
  },

  methods: {
    register() {
      this.successMessage = null;

      if (this.loading) {
        return;
      }

      this.fieldsErrors.agreeWithTerms = !this.agreeWithTerms;

      if (!this.agreeWithTerms) {
        return;
      }

      if (!this.$refs.form.validate()) {
        return;
      }

      if (!this.captcha && !this.debugCaptcha) {
        this.errorMessage = this.captchaErrorMessage;
        return;
      } else {
        this.errorMessage = null;
      }

      this.loading = true;
      this.errorMessage = null;
      this.emptyFieldErrors();

      this.axios
        .post("/auth/register", {
          email: this.email,
          password: this.password,
          captcha: this.debugCaptcha ? '1' : window.grecaptcha.getResponse()
        })
        .then(response => {
          this.loading = false;

          this.successMessage = this.$t("auth.register_confirm_sent_success");
        })
        .catch(error => {
          const response = error.response;

          this.loading = false;
          this.captcha = false;

          if (response.status === 422) {
            this.fieldsErrors = response.data.errors;

            if (this.fieldsErrors.captcha) {
              this.errorMessage = this.captchaErrorMessage;
            }
          }
        });
    },

    emptyFieldErrors() {
      this.fieldsErrors = {
        email: [],
        password: [],
        captcha: null,
        agreeWithTerms: false
      };
    },

    loadCaptchaState() {
      this.axios
        .get("/captcha/login")
        .then(response => {
          this.debugCaptcha = response.data.debug;
        })
        .catch(() => {
          alert("Some error happened");
        });
    }
  }
};
</script>
